import classroomApiHelper from "@/utils/gapi/classroomApiHelper";
import Vue from "vue";
import CourseService from "@learnics/services/src/course";
import SectionService from "@learnics/services/src/section";

export const state = {
  googleClassroomCourses: {},
  googleClassroomCourseWorks: {},
  googleClassroomCourseWorkLoaded: {},
  googleClassroomCourseWorkLoading: {},
  googleClassroomCoursesLoaded: false,
  googleClassroomLearnicsLoaded: false,
  googleClassroomCoursesLoading: false,
};

export const getters = {
  getGoogleClassroomCourses: (state) => state.googleClassroomCourses,
  getGoogleClassroomCourseWorks: (state) => state.googleClassroomCourseWorks,
  getGoogleClassroomCourseWorkLoaded: (state) =>
    state.googleClassroomCourseWorkLoaded,
  getGoogleClassroomCourseWorkLoading: (state) =>
    state.googleClassroomCourseWorkLoading,
  getGoogleClassroomCoursesLoaded: (state) =>
    state.googleClassroomCoursesLoaded,
  getGoogleClassroomCoursesLoading: (state) =>
    state.googleClassroomCoursesLoading,
};

export const mutations = {
  addGoogleClassroomCourse(state, payload) {
    Vue.set(state.googleClassroomCourses, payload.id, payload);
  },
  updateGoogleClassroomCoursesLoadingStatus(state, payload) {
    Vue.set(state, "googleClassroomCoursesLoading", payload);
  },
  updateGoogleClassroomCoursesLoadedStatus(state, payload) {
    Vue.set(state, "googleClassroomCoursesLoaded", payload);
  },
  updateGoogleClassroomLearnicsLoadedStatus(state, payload) {
    Vue.set(state, "googleClassroomLearnicsLoaded", payload);
  },
  addGoogleClassroomCourseWork(state, payload) {
    Vue.set(state.googleClassroomCourseWorks, payload.id, payload);
  },
  updateGoogleClassroomCourseWorkLoadingStatus(state, payload) {
    Vue.set(
      state.googleClassroomCourseWorkLoading,
      payload.courseId,
      payload.status
    );
  },
  updateGoogleClassroomCourseWorkLoadedStatus(state, payload) {
    Vue.set(
      state.googleClassroomCourseWorkLoaded,
      payload.courseId,
      payload.status
    );
  },
};

export const actions = {
  async loadGoogleClassroomCourses({ commit, state, rootState }, payload) {
    const forced = !!payload?.forced;
    if (
      !forced &&
      (state.googleClassroomCoursesLoaded ||
        state.googleClassroomCoursesLoading)
    )
      return;
    commit("updateGoogleClassroomCoursesLoadingStatus", true);
    try {
      const courses = await classroomApiHelper.listCourses();

      for (let i = 0; i < courses.length; i++) {
        commit("addGoogleClassroomCourse", courses[i]);
      }

      console.log("Got courses from Google Classroom API: ", courses);
      commit("updateGoogleClassroomCoursesLoadedStatus", true);
      commit("updateGoogleClassroomCoursesLoadingStatus", false);
    } catch (error) {
      console.error(error);
      commit("updateGoogleClassroomCoursesLoadedStatus", false);
      commit("updateGoogleClassroomCoursesLoadingStatus", false);
      throw error;
    }
  },
  async loadGoogleClassroomCourseWorks({ commit, state, rootState }, payload) {
    const forced = !!payload?.forced;
    const courseId = payload?.courseId;
    if (
      !forced &&
      (state.googleClassroomCourseWorkLoaded[courseId] ||
        state.googleClassroomCourseWorkLoading[courseId])
    )
      return;
    commit("updateGoogleClassroomCourseWorkLoadingStatus", {
      courseId,
      status: true,
    });

    try {
      const courseWorks = await classroomApiHelper.listCourseWork(courseId);

      console.log(
        "Got courseWork objects from Google Classroom API: ",
        courseWorks
      );
      for (let i = 0; i < courseWorks.length; i++) {
        commit("addGoogleClassroomCourseWork", courseWorks[i]);
      }
      commit("updateGoogleClassroomCourseWorkLoadedStatus", {
        courseId,
        status: true,
      });
    } catch (error) {
      console.error(error);
      commit("updateGoogleClassroomCourseWorkLoadedStatus", {
        courseId,
        status: false,
      });
    } finally {
      commit("updateGoogleClassroomCourseWorkLoadingStatus", {
        courseId,
        status: false,
      });
    }
  },

  async loadLearnicsSectionsAndCoursesForGoogleClassroomCourses(
    { commit, state, rootState },
    payload
  ) {
    const forced = !!payload?.forced;
    if (!forced && !state.googleClassroomLearnicsLoaded) {
      return;
    }
    commit("updateGoogleClassroomLearnicsLoadedStatus", true);

    const classroomCourseIds = Object.keys(state.googleClassroomCourses);

    for (let i = 0; i < classroomCourseIds.length; i++) {
      await SectionService.getSectionsByClassroomCourseId(
        classroomCourseIds[i],
        async (section) => {
          console.log(
            "Found existing section for course",
            classroomCourseIds[i],
            ":",
            section
          );
          commit("addSection", section);
          const course = await CourseService.getCourse(section.courseId);
          commit("addCourse", course);
        }
      );

      await CourseService.getCoursesByClassroomCourseId(
        classroomCourseIds[i],
        (course) => {
          commit("addCourse", course);
        }
      );
    }
  },
};
