import { GoogleAuthProvider } from "firebase/auth";
import { DEFAULT_SCOPES } from "@learnics/services/src/utils/gapiScopes";
import { getAllExtensionControllers } from "@/services/extensionControllers";
import { myTabId } from "@/services/myTabId";

export function createLoginArguments(
  state,
  user,
  scopes,
  prompt = "none", // We always default to none.  If that fails, the popup will automatically upgrade to select_account.  If that fails, the call will be tried once more with "consent".
  redirect = false,
  linkingGoogleAccount = false,
  showWarning = false,
  warningMessage = "",
  redirectUrl = null
) {
  scopes ||= DEFAULT_SCOPES;
  const extensions = getAllExtensionControllers().filter((extension) =>
    extension.isAtLeast("3.39.0")
  );
  let extensionId, tabId, messages;
  if (extensions.length > 0) {
    tabId = myTabId.get();
    console.warn("Creating login arguments with tab ID " + tabId);
    // If we have at least one extension connected, let it take over the login process
    extensionId = extensions[0].extensionId;
    messages = [
      {
        action: "sendMessageToLearnicsWebApp",
        tabId: tabId,
        message: {
          action: "loginSuccess",
        },
      },
    ];
  }
  let googleId = null;
  for (let i = 0; i < user?.providerData?.length; i++) {
    const provider = user?.providerData?.[i];
    if (provider.providerId === GoogleAuthProvider.PROVIDER_ID) {
      googleId = provider.uid;
      break;
    }
  }

  return {
    scope: scopes,
    prompt,
    state: {
      uid: user?.uid || null,
      ...(redirect
        ? {
            base64RedirectUrl: btoa(redirectUrl || window.location.href),
          }
        : {}),
      tabId, // This is the tab ID we're logging in for.  Possibly null.
      extensionId, // This is the extensionId that is handling the login.  Possibly null.
      messages, // Messages to execute after login.  Possibly null or empty.
      linkingGoogleAccount: !!linkingGoogleAccount, // Whether or not this auth flow will just link the account or not.
      ...(showWarning && warningMessage
        ? {
            showWarning: true,
            warningMessage: warningMessage,
          }
        : {}),
      ...(state || {}),
    },
    login_hint: googleId || user?.email || null,
    include_granted_scopes: true,
    access_type: "offline",
  };
}
