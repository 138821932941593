import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import { store } from "@/store";
import { auth } from "@learnics/services/src/analyticsConfig";
import VueGoogleCharts from "vue-google-charts/legacy";
import routes from "@/views/routes";
import { signInWithCustomToken } from "firebase/auth";
import { synchronizeUserAccounts } from "@/utils/synchronizeUserAccounts";

Vue.config.devtools = true;

Vue.use(Router);
Vue.use(Meta);
Vue.use(VueGoogleCharts);

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  // This probably isn't needed, given that we're using the "history" option above.  If bookmarks are needed in the
  // future, however, it could present unnecessary side effects and should be removed at that point.
  if (to.fullPath.substring(0, 2) === "/#") {
    const path = to.fullPath.substring(2);
    next({ path });
    return;
  }
  let query = { redirect: to.redirectedFrom || to.path, ...to.query };

  if (to.query.from_cloud_function && to.query.custom_token) {
    await signInWithCustomToken(auth, to.query.custom_token);
    delete to.query.custom_token;
    delete to.query.from_cloud_function;
    let resolvedRoute = router.resolve({
      name: to.name,
      params: to.params,
      query: { ...to.query },
    });

    window.location.assign(resolvedRoute.href);
    return;
  }

  // Attempt to log in with the custom LTI token
  if (to.query.lti_context) {
    const context = JSON.parse(decodeURIComponent(to.query.lti_context + ""));
    console.log("Picked up LTI context at router: ", context);
    console.log(
      "Picked up LTI context at router: ",
      JSON.stringify(context, null, 2)
    );
    store.commit("setLtiContext", context);
  }

  const ltiContext = store.getters.getLtiContext;

  let user = auth.currentUser;
  let customToken = to.query.custom_token || "";
  if (customToken) {
    console.log("Got custom token: ", customToken);
    console.log(
      "Attempting custom token login.",
      auth.currentUser?.uid,
      ltiContext?.user,
      ltiContext,
      auth.currentUser
    );
    console.log("Signing in with customToken ", customToken);

    try {
      const customTokenResult = await signInWithCustomToken(auth, customToken);
      user = customTokenResult.user;
    } catch (e) {
      console.log("Error sending login message to extension: ", e);
    }
  }
  const isAuthFreeZone =
    to.name === "LoginView" ||
    to.name === "LogoutView" ||
    to.name === "StudentLoginView" ||
    to.name === "StudentLogoutView" ||
    to.name === "ExtensionLogoutView" ||
    to.name === "CustomTokenLoginView" ||
    to.name === "ClassroomAssignmentFormView" ||
    to.name === "TeacherLogBackInView" ||
    to.name === "SyncStudentLoginView" ||
    to.name === "SyncAccountsView" ||
    to.name === "GoogleLoginView" ||
    to.name === "GoogleLoginWarningView" ||
    to.name === "GoogleOauth2CallbackView" ||
    to.name === "ExtensionUninstallView" ||
    to.name === "GetCustomTokenView" ||
    to.name === "CustomTokenSignInView" ||
    to.name === "LearnicsUTermsOfServiceView" ||
    to.name === "LearnicsUTourView" ||
    to.name === "DriveFileSelectView" ||
    to.name === "ExtensionUninstallSuccessView" ||
    to.name === "TeacherTryForFreeRedirectView" ||
    to.name === "TeacherTryForFreeView";
  if (customToken) {
    delete to.query.custom_token;
    const query = { ...to.query };
    if (to.name === "CustomTokenSignInView") {
      // Store and save the custom token for later use in the sync account view,
      // but rename it to avoid the router picking it up again.
      query.custom_token_remnant = customToken;
    }

    if (ltiContext) {
      query.lti_context = encodeURIComponent(JSON.stringify(ltiContext));
    }
    let resolvedRoute = router.resolve({
      name: to.name,
      params: to.params,
      query: query,
    });

    window.location.assign(resolvedRoute.href);
    return;
  }

  // Note this is left here just in case, because it does work to do it this way
  // and may ultimately need to be done.  It's just a lot more complicated when
  // synchronization is called this much, and we seem to be able to get away with
  // doing it less often.
  const SYNCHRONIZE_ON_ROUTE_CHANGE = false;

  if (
    SYNCHRONIZE_ON_ROUTE_CHANGE &&
    !!ltiContext &&
    (!isAuthFreeZone ||
      to.name === "LoginView" ||
      to.name === "GoogleLoginView" ||
      to.name === "GoogleLoginWarningView")
  ) {
    console.log("Synchronizing user accounts...");
    const startTime = +new Date();
    const promise = synchronizeUserAccounts(user, !!ltiContext).finally(() => {
      const timeElapsed = +new Date() - startTime;
      console.log("Synchronized user accounts in " + timeElapsed + " ms.");
    });
    if (
      to.name === "LoginView" ||
      to.name === "GoogleLoginView" ||
      to.name === "GoogleLoginWarningView"
    ) {
      await promise;
    }
  }

  if (user || isAuthFreeZone) {
    document.documentElement.style.overflow = "auto";
    next();
    return;
  }

  // If the user hasn't been able to authenticate by now, redirect to the login page, being sure to store where they
  // were trying to go, so they can be redirected back to the original location after login.
  next({ name: "LoginView", query });
});

export default router;
