function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}
export default {
  path: "session/:session_id",
  props: true,
  component: lazyLoad("SessionPopupRoot"),
  children: [
    {
      path: "sleepStopLogging",
      name: "SleepStopLoggingView",
      props: true,
      component: lazyLoad("SleepStopLoggingView"),
    },
    {
      path: "exportNotes",
      name: "ExportNotesView",
      props: true,
      component: lazyLoad("ExportNotesView"),
    },
  ],
};
